@import "theme/reset";
@import "theme/main";

html  {
  min-width: fit-content;
}

body {
  min-width: fit-content;
}
