@import "abstract/variables.scss";

.main_button {
  cursor: pointer;
  color: white;
  background-color: $primary100;
  width: 100%;
  border-radius: $border-radius-md;
  height: 3.125rem;
  transition: all 0.2s ease-in-out;

  &.disabled, &[disabled] {
    background-color: $gray50;
    color: $white;
    cursor: default;
  }
}

.main_button_fit {
  @extend .main_button;
  width: fit-content;
  padding-inline: 1rem;
  height: 2.5rem;
}

.no-top-rounded {
  @extend .main_button;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.button_soft {
  @extend .main_button;
  background-color: $primary40;
  border: 1px solid $primary40;
  color: black;
  padding: 0.75em 1.25em;
}

.button_outline {
  @extend .main_button;
  padding: 0.75em 1.25em;
  background-color: white;
  color: $primary100;
  border: 1px solid $primary100;
  box-shadow: $shadow-black;
}

.button_outline_inactive {
  @extend .main_button;
  padding: 0.75em 1.25em;
  background-color: transparent;
  color: $primary100;
  border: 1px solid $primary100;
}

.button_simple {
  color: $primary100;
  width: fit-content;
  padding-inline: 1rem;

  &:disabled {
    color: $gray50;
    cursor: default;
  }
}
