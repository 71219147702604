.focus-removes{
  outline: none !important;
  box-shadow: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

@for $i from 1 through 100 {
  .gap-#{$i} {
      gap: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }
  .ml-#{$i} {
    margin-left: #{$i}rem;
  }
  .mr-#{$i} {
    margin-right: #{$i}rem;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

.h-100{height: 100%}
.w-100{width: 100%}
.h-70{height: 70%}
.w-70{width: 70%}
.h-60{height: 60%}
.w-60{width: 60%}
.h-50{height: 50%}
.w-50{width: 50%}
.h-40{height: 40%}
.w-40{width: 40%}
.h-30{height: 30%}
.w-30{width: 30%}
