* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: black;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}
