.skeleton-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #eee;
    background: linear-gradient(110deg, $primary5 8%, $white 18%, $primary5 33%);
    background-size: 200% 100%;
    animation: 1.5s skeleton linear infinite;
}

.skeleton-text {
    width: 15rem;
    height: 1rem;
    background: #eee;
    background: linear-gradient(110deg, $primary5 8%, $white 18%, $primary5 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s skeleton linear infinite;
}