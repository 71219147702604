/*
***********************
Colors
***********************
*/

/*
* Corporative primary colors
*/

$primary100: #1814F3;
$primary70: #6F7DFF;
$primary40: #ADB5FF;
$primary20: #D6DAFF;
$primary10: #EAECFF;
$primary5: #F5F6FF;

/*
* Corporative secondary colors
*/

$secondary100: #0D1763;
$secondary80: #485487;
$secondary60: #626EA2;
$secondary30: #B6B9D0;
$secondary20: #C6D0E1;
$secondary10: #E7E8EF;

/*
* Neutrals colors
*/

$black: #000000;
$gray80: #333333;
$gray50: #808080;
$gray20: #CCCCCC;
$gray05: #F2F2F2;
$white: #FFFFFF;

/*
* Utility decorative colors
*/

/*
* SUCCESS
*/
$success_strong: #117C74;
$success_middle: #16DBCC;
$success_soft: #DCFAF8;

/*
* SAFE
*/
$safe_strong: #53793C;
$safe_middle: #96C779;
$safe_soft: #E9FADF;

/*
* WARNING
*/
$warning_strong: #976400;
$warning_middle: #FFBB38;
$warning_soft: #FFF5D9;

/*
* RISK
*/
$risk_strong: #A24F29;
$risk_middle: #F58958;
$risk_soft: #FFE9DF;

/*
* DANGER
*/
$danger_strong: #B12054;
$danger_middle: #E53172;
$danger_soft: #FADCE7;

/*
* INFORMATION
*/
$info_strong: #2259FF;
$info_middle: #4E7AFE;
$info_soft: #E7EDFF;

/*
* EMPTY
*/
$empty_dark: #686868;
$empty_middle: #929191;
$empty_soft: #ECEDED;


$decorative_danger: #FB727F;
$decorative_warning: #FBA259;
$decorative_success: #31CA54;

/*
* Utility accesible colors
*/

$accesible_danger: #CE3D4C;
$accesible_warning: #B15E19;
$accesible_success: #10832A;

/*
* Legacy colors
TODO: Remove this colors when the legacy colors are removed
*/

$legacy_primary_light: #F9F9FF;
$legacy_primary_semilight: #F2F4FF;
$legacy_primary_semilight2: #F3F4FF;
$legacy_secondary_light: #6E82FF;
$legacy_disabled: #F1F2FF;
$legacy_disabled_font: #ABB6FF;
$legacy_black_font2: #9E9E9E;
$legacy_gray_font: #9C9C9C;
$legacy_placeholder: #E2E2E2;
$legacy_gray_line: #DDDDDD;
$legacy_alert: #FCD59F;

/*
***********************
Typography
***********************
*/

$span: 0.8rem;
$text_xs: 0.75rem;
$text_s: 0.87rem;
$text_m: 1rem;
$h2: 1.1rem;
$h1: 1.5rem;

/*
***********************
Spacing
***********************
*/

$border-radius-md: 0.5rem;

/*
***********************
Shadows
***********************
*/

$shadow-black: 0 0 10px rgba(black, 0.1);
$shadow-primary: 0 0 10px rgba(51, 69, 247, 0.1);
